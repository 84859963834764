

















import { Component, Vue, Prop } from "vue-property-decorator";
import { RedCapital, SelectedCountry, RedCapitalConfig } from "@/configuration";
import { EventBus } from "@/main";

function cleanValue(value: any) {
  return value.toString().replace(/[.|,]/gi, ".");
}
function cleanValue2(value: any) {
  return value.toString().replace(/[.|,]/gi, "");
}


function currencyParser(value: any) {
  let cValue = value;
  return new Intl.NumberFormat("PE").format(cValue)
}

Vue.directive("currency-parser", {
  bind(el, binding) {
    const inputEvents = ["keypress"];

    let formatter = (e: any) => {
      if (e.key.match(/[^0-9|,|.]/gim)) {
        e.preventDefault();
        // console.log(e)
      }
      let value = e.target.value;

//console.log('number',Number.isNaN(Number.parseFloat(cleanValue(value)).toFixed(2)))

     // let v = Number.parseFloat(cleanValue(value)).toFixed(2)
      if (Number.isNaN(cleanValue2(value))) {
        e.target.value = 3000
      } 
    };

    inputEvents.forEach(event => {
      el.addEventListener(event, formatter);
    });
  }
});

@Component({
  computed: {
    minAndMax() {
      return [
        (v: any) =>
          Number.parseFloat(cleanValue(v)) >= Number.parseFloat(this.$props.min) ||
          `El monto es menor a <a onclick="SetAmount(${this.$props.min}, ${
            this.$data.uuid
            // @ts-ignore
          })">${this.$options.filters.currency(this.$props.min)}</a>`,
        (v: any) =>
          Number.parseFloat(cleanValue(v)) <= Number.parseFloat(this.$props.max) ||
          `El monto es mayor a <a onclick="SetAmount(${this.$props.max}, ${
            this.$data.uuid
            // @ts-ignore
          })">${this.$options.filters.currency(this.$props.max)}</a>`
      ];
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      money: 0,
      isFocus: false,
      uuid: Math.random()
    };
  },
  watch: {
    value(val) {
     /* this.$data.money = Number(cleanValue(this.$props.value)).toLocaleString(
        RedCapitalConfig(SelectedCountry).Currency.LocaleString
      );
      // @ts-ignore
      this.onChange();*/
    },
    money(val) {
      // @ts-ignore
      this.onChange();
    }
  },
  created() {
    this.$data.money = Number(cleanValue(this.$props.value)).toLocaleString('PE');
  },
  beforeMount() {
    // @ts-ignore
    window.SetAmount = (amount: any, uuid: any) => {
      if (this.$data.uuid == uuid) {
        this.$data.money = amount;
      }
    };
  },
  mounted() {
    /* console.log(this) */
  },
  methods: {
    autoformat() {
      /* console.log(Number(cleanValue(this.$data.money)))
      console.log(this.$props.min)
      console.log(this.$props.max)
      if(Number(cleanValue(this.$data.money)) > this.$props.max) {
        this.$data.money = this.$props.max
        EventBus.$emit('snack-error','testmax')
      }
      if(Number(cleanValue(this.$data.money)) < this.$props.min) {
        this.$data.money = this.$props.min
        EventBus.$emit('snack-error','testmin')
      } */
    },
    format(val: any) {
      // @ts-ignore
      return this.$options.filters.currency(val);
    },
    setThing() {
      /* console.log(this) */
    },
    onChange() {
      /* isFocused:false */
      this.$emit("input", Number(cleanValue(this.$data.money)));
    },
    focus() {
      this.$emit("focus");
      this.$data.isFocus = true;
    },
    blur() {
      this.$emit("blur");
      this.$data.isFocus = false;
    }
  }
})
export default class Currency extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 0 }) value!: number;
  @Prop({ default: -1000000000000 }) min!: number;
  @Prop({ default: 1000000000000 }) max!: number;
  @Prop() label!: string;
  @Prop() prefix!: string;
}
