













































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Axios from 'axios'
import { RedCapital, SelectedCountry, Configuration, TitleTemplateDashboard, Countries } from '@/configuration'
import Currency from '@/components/CurrencyRounded.vue'
import ReusableButton from '@/components/ReusableButton.vue'
import API from '@/api'
import DniField from '@/components/DniField.vue'
import { EventBus } from '@/main'

@Component({
  computed: {
    ...mapState(['user', 'business', 'lastSimulation']),
  },
  metaInfo: {
    title: 'Solicitar credito',
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return{
      RedCapital,
      SelectedCountry,
      btn: {
        uploadCredit: {
          clickable: false,
          loading: false,
          isOk: null,
          successText: 'Petición enviada',
          errorText: 'Error al hacer la petición',
        },
      },
      statics: {
        business: null,
        businessName: null,
        businessSelected: 0,
      },
      valid: true,
      dialog: false,
      errorMessage: null,
      typeSelected: null,
      disableWarrantyInputs: true,
      credit: {
        amount: this.$store.state.lastSimulation != null ? this.$store.state.lastSimulation.monto : 0,
        reason: null,
        term: this.$store.state.lastSimulation != null ? 12 : null,
        warranty: {
          selected: 1,
          cost: null,
          debt: null,
        },
      },
      warrantyTypes: [
        {
          id: 1,
          text: 'Ninguna',
        },
        {
          id: 2,
          text: 'Casa',
        },
        {
          id: 3,
          text: 'Oficina',
        },
        {
          id: 4,
          text: 'Terreno',
        },
        {
          id: 5,
          text: 'Industrial',
        },
        {
          id: 6,
          text: 'Local comercial',
        },
        {
          id: 7,
          text: 'Agrícola',
        },
      ],
      rules: {
        notEmpty: [
          (v: any) => !!v || 'Este campo es requerido',
        ],
        amount: [
          (v: any) => !!v || 'Monto a solicitar es requerido',
          (v: any) => /^\d+$/g.test(v) || 'Tiene que ser solo numeros'
        ],
        warranty: {
          cost: [
            (v: any) => !!v || 'Valor de la garantía es requerido',  
          ],
          debt: [
            (v: any) => !!v || 'Deuda de la garantía es requerido',  
          ]
        },
        reason: [
          (v: any) => !!v || 'Motivo de la solicitud es requerido',
        ],
        terms: [
          (v: any) => !!v || 'Plazo de la garantía es requerido',
          (v: any) =>/^\d+$/g.test(v) || 'Tiene que ser solo numeros'
        ],
      },
    }
  },
  async beforeMount() {
    this.$data.statics.business = await API.getBusiness()
    if(this.$store.state.business != null) {
      this.$data.statics.businessSelected = this.$store.state.business
    } else {
      this.$data.statics.businessSelected = 0
    }
  },
  watch: {
    credit: {
      handler() {
        // @ts-ignore
        if (this.$refs.credit.validate()) {
          if (this.$data.credit.warranty.selected > 0) {
            this.$data.btn.uploadCredit.clickable = true
          } else {
            this.$data.btn.uploadCredit.clickable = false
          }
        } else {
          this.$data.btn.uploadCredit.clickable = false
        }
      },
      deep: true,
    },
    'credit.warranty.selected'(val) {
      if (val == 0 || val == 1) {
        this.$data.disableWarrantyInputs = true
      } else {
        this.$data.disableWarrantyInputs = false
      }
    }
  },
  methods: {
    uploadCredit() {
      // @ts-ignore
      if (this.$refs.credit.validate()) {
        this.$data.valid = true
        this.$data.errorMessage = null

        API.request.accion(
          this.$data.statics.businessSelected,
          this.$data.credit.reason,
          this.$data.credit.amount,
          this.$data.credit.term,
          this.$data.credit.warranty.selected >= 2 ? {
            garantia_id: this.$data.credit.warranty.selected,
            valor: this.$data.credit.warranty.cost,
            deuda: this.$data.credit.warranty.debt,
          } : null,
          this.$store.state.questionId).then( data => {
          this.$data.dialog = true
          setTimeout(() => {
            this.$store.commit('clearQuestionId')
            this.$router.push({
              path: '/gracias'
            })
          }, Configuration.Redirection.timeout)
        }).catch( error => {
          this.$data.errorMessage = 'Ha ocurrido un error, intente nuevamente'
          EventBus.$emit('snack-error', error.message)
        })
      }
    },
  },
  components: {
    Currency,
    ReusableButton,
    DniField,
  }
})
export default class RequestCredit extends Vue {}
